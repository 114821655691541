import { createMuiTheme } from '@material-ui/core/styles'
import { blue, yellow, red, lime, lightBlue, purple, lightGreen } from '@material-ui/core/colors'
import ubuntu from 'typeface-ubuntu-mono'

const primary = lightBlue.A700
const secondary = yellow.A700
const white = '#ffffff'
// eslint-disable-next-line @typescript-eslint/no-use-before-define
const error = red[500]

const primaryText = white
// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Ubuntu Mono'
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [ubuntu],
      },
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: primary
    },
    secondary: {
      main: secondary
    },
    error: {
      main: error
    },
    background: {
    },
    text: {
      primary: primaryText
    }
  }
})

export { theme }
